@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Montserrat:wght@200;400;600&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-color: #fdf0d5;
  font-family: "Montserrat", sans-serif;
  color: #d64933;
}
/* Header */
.head {
  height: 12vh;
  background-color: #36382e;
  color: #fdf0d5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px black solid;
}
.head h1 {
  font-family: "Courgette", cursive;
  font-size: 5rem;
  margin: auto 2%;
  padding-top: 1%;
}
.head h1:hover {
  color: #d64933;
}
nav {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
}
nav a {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: #fdf0d5;
  padding: 0 7%;
}
nav a:hover {
  text-decoration: underline #7d8491;
  color: #d64933;
}

/* Forms */
.loginBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formBG {
  margin-top: 1%;
  height: 50vh;
  background-image: url("https://media.womensweekly.com.sg/public/2016/12/20-Sure-Win-Dishes-To-Bring-To-A-Potluck-Party.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mainForm {
  border: 2px solid black;
  border-radius: 25px;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: #fdf0d5;
  margin: 4%;
}

.mainForm h2 {
  font-family: "Courgette", cursive;
  text-align: center;
  font-size: 2rem;
  margin: 1%;
}

.item {
  margin: 10% auto;
}
.itemSub {
  display: flex;
  justify-content: center;
}
.itemSub button {
  margin-top: 5%;
  font-size: 1.2rem;
  width: 50%;
}

.errorMsg {
  font-size: 0.8rem;
  color: red;
}

/* Main Body */
.mainBody {
  padding-top: 2%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.mainBody h2 {
  font-size: 2rem;
  margin: 2% 0;
}
.mainBody img {
  width: 50%;
  border-radius: 20px;
}

/* Event Body */
.eventBody {
  border: 2px solid black;
  color: black;
  border-radius: 25px;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: #fdf0d5;
  margin: 3% auto;
  box-shadow: 10px 10px 10px #7d8491;
}
.divH2 {
  padding: 1%;
  border-bottom: 2px black solid;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  background-color: #36382e;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
}
.eventBody h2 {
  font-family: "Courgette", cursive;
  padding: 1% 2%;
  font-size: 4.5rem;
  color: #d64933;
}
.infoBox {
  padding: 1%;
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
}
.eventBody p {
  padding: 2%;
  font-size: 1.5rem;
}
.btnBox {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-flow: column;
  margin: 1% 0.5% 0 0;
}
.eventBtn {
  margin-bottom: 10%;
  font-size: 1.2rem;
  background-color: #fdf0d5;
  color: #36382e;
  border-radius: 10px;
}
.eventBtn:hover {
  background-color: #402a2c;
  color: #7d8491;
}

/* Event Form */
.eventForm {
  border: 2px solid black;
  color: black;
  border-radius: 25px;
  width: 30%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: #36382e;
  margin: 2%;
}

.eventForm h2 {
  font-family: "Courgette", cursive;
  margin: 2% auto;
  font-size: 4rem;
  color: #d64933;
}
.eventFBox {
  background-color: #fdf0d5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  padding: 5%;
}

.eventItem {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  font-size: 1.5rem;
  margin: 4%;
  padding-bottom: 5%;
}
.eventItemSub {
  display: flex;
  justify-content: center;
}
.eventItemSub button {
  margin-bottom: 10%;
  font-size: 1.2rem;
  width: 50%;
  background-color: #36382e;
  color: #fdf0d5;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #7d8491;
}
.eventItemSub button:hover {
  background-color: #402a2c;
  color: #7d8491;
}

.eventFBox input {
  margin-top: 4%;
}

/* Profile */
.cards {
  display: flex;
  flex-flow: row wrap;
}
.profile-card {
  box-shadow: 10px 10px 10px #7d8491;
  text-align: center;
  border: 2px solid black;
  color: #fdf0d5;
  border-radius: 25px;
  min-width: 25%;
  max-width: 30%;
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  background-color: #36382e;
  margin: 2%;
}
.profile-card h2 {
  margin: 2%;
  font-size: 2rem;
  color: #d64933;
}
.profile-card p {
  margin: 1% 0;
}
.profile-card div {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  margin: 2% auto;
}

.addEventBtn {
  background-color: #36382e;
  color: #fdf0d5;
  width: 10%;
  font-size: 1.5rem;
  margin: 0 5%;
  padding: 1%;
  border-radius: 30px;
  box-shadow: 5px 5px 10px #7d8491;
}
.addEventBtn:hover {
  background-color: #402a2c;
  color: #7d8491;
}

/* Break Points */
@media (max-width: 800px) {
  .head h1 {
    font-size: 3rem;
  }
  .profile-card {
    width: 50%;
  }
  nav a {
    font-size: 1.5rem;
  }
  .eventForm {
    width: 50%;
  }
  .eventForm h2 {
    font-size: 2rem;
  }
  .addEventBtn {
    width: 20%;
  }
  .eventBtn {
    width: 30%;
  }
}
@media (max-width: 500px) {
  .head h1 {
    font-size: 2rem;
  }
  .mainForm {
    padding: 2% 10%;
    margin-top: 12%;
  }
  nav a {
    font-size: 1rem;
  }
  .eventForm {
    width: 70%;
  }
  .eventForm h2 {
    font-size: 2rem;
  }
  .addEventBtn {
    width: 30%;
  }
  .mainForm {
    width: 25%;
  }
}
